import React from "react";

const Footer = () => (
  <footer className="bg-gray-800">
        <nav className="flex justify-between max-w-5xl mx-auto p-4 md:p-8 text-sm">
          <div className="text-gray-500">
            Created by{` `}
            <a
              className="font-bold no-underline text-gray-500"
              href="https://genevievehoffman.com"
              target="blank"
            >
              Hyper Visual
            </a>
            <div>Illustrations by{` `}
              <a 
              className="font-bold no-underline text-gray-500"
              href="https://www.behance.net/frush" 
              target="blank">
                 Giuditta Valentina Gentile
              </a>
              </div>
          </div>

          <p className="text-gray-500">Copyright © 2020 Manuel Pimenta</p>
        </nav>
      </footer>
)

export default Footer;