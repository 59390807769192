import React from "react";
import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Header from "./header";
import Footer from "./footer";
// import { Waypoint } from "react-waypoint";
// import SEO from "../components/seo";
import Nav from '../components/nav';
import "./layout.css";

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            description
            menuLinks {
              name
              link
              label
            }
          }
        }
      }
    `}
    render={ ({site}) => {
      // console.log(site.siteMetadata.description)
      // console.log(location)
      return (<React.Fragment>
        <Helmet
          title={'title'}
          meta={[
            { name: 'description', content: 'Virtual Moon Coming Soon' },
            { name: 'keywords', content: 'virtual moon, virtual reality, space' },
          ]}
        >
        </Helmet>
          <Header description={site.siteMetadata.description} location={location} />
          <Nav menuLinks={site.siteMetadata.menuLinks}/>
          <main className="relative flex flex-col flex-1 p-6 md:justify-center mx-auto bg-gray-900">
          {children}
        </main>
        <Footer />
      </React.Fragment>)
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object
};

export default Layout;
