import React from 'react'
import { Link } from "gatsby";
// import Scrollspy from 'react-scrollspy'
// import Scroll from './scroll'
import PropTypes from 'prop-types'
// import logo from "../images/vm_moon_logo.svg"
import logo from "../images/VirtualMoonLogo.gif"


const Nav = ({ menuLinks }) => {
  console.log(menuLinks)

  return (
    <nav id="nav" className='sticky w-screen p-4 bg-black top-0 z-10'>
      <div className="w-full flex flex-row items-center justify-around">
        {
          menuLinks.map((menuLink, index) => (
            
              <Link 
                key={menuLink.name}
                to={menuLink.link}
                className="block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6"
              >
                {index === 0 ? <img src={logo} className="w-12" /> : <div>{menuLink.label}</div>}
              </Link>

            )
          )
        }
      </div>
    </nav>
  )
}
Nav.propTypes = {
  menuLinks: PropTypes.array
};

export default Nav
