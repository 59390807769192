// import { graphql, useStaticQuery, Link } from "gatsby";
// import { graphql, useStaticQuery } from "gatsby";
// import React, { useState } from "react";
import React from "react";
import PropTypes from "prop-types";
import { Spring, animated } from 'react-spring/renderprops';
import { AnchorLink } from "gatsby-plugin-anchor-links";

// import { Transition, animated } from 'react-spring/renderprops'

// import Img from "gatsby-image";
import logo from "../images/VirtualMoonLogoTransparent_faster.gif"
import headerImg from "../images/headerimage_new.png"


const Header = ({ description, location }) => {
  // console.log(description)
  if (location) console.log(location.pathname)
  if (location) console.log(location.pathname === '/')
  return (
    <header >
      {/* <Transition
        native
        items={location}
        keys={location.pathname === '/'}
        from={{ height: 0 }}
        enter={[{ height: 'auto' }]}
        leave={{ height: 0 }}
      >
        {location => location.pathname === '/' &&
          (props => <animated.div style={props}>
              <div 
                className="block overflow-visible bg-gray-900 bg-cover headerHeight"
                style= {{  ...props, backgroundImage: `url(${headerImg})`}}>
            </div> 
          </animated.div>)
        }
      </Transition> */}
      <Spring
        native
        force
        // reset
        // reverse
        config={{ tension: 2000, friction: 200, precision: 1 }}
        from={{ height:location.pathname === '/' ?  0 : 'auto' }}
        to={{ height: location.pathname === '/' ? 'auto' : 0}}>
        {(props) => {
          console.log(props)
          return (<animated.div className="headerHeight" style={props}>
            {/* <span className="absolute font-light gittext-white font-title mt-5 ml-3"><img src={logo} alt="Virtual Moon Logo" style={{height: '100px'}}/></span> */}
            <div 
              className="block overflow-visible bg-gray-900 bg-cover headerHeight"
              // style= {{ backgroundImage: `url(${headerImg})`}}
            >
              <iframe src="https://moon.e-spaces.com/" width="100%" height="100%"></iframe>
            </div> 
            <div className="absolute font-light text-6xl text-white font-title -mt-160 ml-4 flex">
            {description}
            </div>
            <AnchorLink className="absolute font-light text-3xl font-title -mt-120 ml-8" to="#what-is-virtual-moon" title="What is Virtual Moon?"/>
          </animated.div>)
        }}
      </Spring>
      {/* { location.pathname === '/' &&
          <div 
          className="block overflow-visible bg-gray-900 bg-cover headerHeight"
          style= {{ 
            backgroundImage: `url(${headerImg})`,
          }}>
        </div> 
      } */}
    </header>
  )
}

Header.propTypes = {
  description: PropTypes.string,
  location: PropTypes.object
};

export default Header;
